<template>
  <div>
    <el-form class="login-form"
             status-icon
             :rules="loginRules"
             ref="loginForm"
             :model="loginForm"
             label-width="0">
      <el-form-item prop="username">
        <el-input size="small" class="inputSb"
                  @keyup.enter.native="handleLogin"
                  v-model="loginForm.username"
                  placeholder="请输入账号">
          <i slot="prefix"
             class="icon-yonghu"></i>
        </el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input size="small" class="inputSb"
                  @keyup.enter.native="handleLogin"
                  :type="passwordType"
                  v-model="loginForm.password"
                  placeholder="请输入密码">
          <i class="el-icon-view el-input__icon"
             slot="suffix"
             @click="showPassword"></i>
          <i slot="prefix"
             class="icon-mima"></i>
        </el-input>
      </el-form-item>
      <el-form-item prop="code">
        <el-row :span="24">
          <el-col :span="16">
            <el-input size="small"  class="inputSb"
                      @keyup.enter.native="handleLogin"
                      :maxlength="code.len"
                      v-model="loginForm.code"
                      placeholder="请输入验证码">
              <i slot="prefix"
                 class="icon-yanzhengma"></i>
            </el-input>
          </el-col>
          <el-col :span="8">
            <div class="login-code">
            <span class="login-code-img"
                  @click="refreshCode"
                  v-if="code.type == 'text'">{{code.value}}</span>
              <img :src="code.src"
                   class="login-code-img"
                   @click="refreshCode"
                   v-else/>
              <!-- <i class="icon-shuaxin login-code-icon" @click="refreshCode"></i> -->
            </div>
          </el-col>
        </el-row>

      </el-form-item>
      <el-checkbox v-model="checked"><font class="text-white" >记住账号</font></el-checkbox>
      <el-form-item>
        <el-button type="primary"
                   size="small"
                   @click.native.prevent="handleLogin"
                   class="login-submit">登录</el-button>
      </el-form-item>
    </el-form>

  </div>
</template>

<script>
  import {randomLenNum} from '@/util/util'
  import {mapGetters} from 'vuex'

    export default {
        name: 'userlogin',
        data() {
            return {
              thirdPartyForm: {
                code: '',
                state: ''
              },
              loginForm: {
                username: '',
                password: '',
                code: '',
                redomStr: ''
              },
              checked: false,
              code: {
                src: '/code',
                value: '',
                len: 4,
                type: 'image'
              },
              loginRules: {
                username: [
                  {required: true, message: '请输入账号', trigger: 'blur'}
                ],
                password: [
                  {required: true, message: '请输入密码', trigger: 'blur'},
                  {min: 6, message: '密码长度最少为6位', trigger: 'blur'}
                ],
                code: [
                  {required: true, message: '请输入验证码', trigger: 'blur'},
                  {min: 4, max: 4, message: '验证码长度为4位', trigger: 'blur'}
                ]
              },
              passwordType: 'password'
            };
        },
      created() {
        this.refreshCode();
      },
      mounted() {
      },
      computed: {
        ...mapGetters(['tagWel'])
      },
      props: [],
      methods: {
        refreshCode() {
          this.loginForm.code = ''
          this.loginForm.randomStr = randomLenNum(this.code.len, true)
          this.code.type === 'text'
                  ? (this.code.value = randomLenNum(this.code.len))
                  : (this.code.src = `${window.location.origin}/code?randomStr=${this.loginForm.randomStr}`)
        },
        showPassword() {
          this.passwordType == ''
                  ? (this.passwordType = 'password')
                  : (this.passwordType = '')
        },
        handleLogin() {
          this.$refs.loginForm.validate(valid => {
            if (valid) {
              this.$store.dispatch('LoginByUsername', this.loginForm).then(() => {
                // debugger;
                this.$store.commit('SET_TOP_MENU_INDEX', 0)
                this.$router.go(0)
                this.$router.push({path: this.tagWel.value})
              }).catch((e) => {
                console.log(e)
                this.refreshCode()
              })
            }
          });
        }
      }
    };
</script>


<!--
  input:-webkit-autofill , textarea:-webkit-autofill, select:-webkit-autofill {
  -webkit-text-fill-color: #fff !important;
  -webkit-box-shadow: 0 0 0px 1000px transparent  inset !important;
  background-color:transparent;
  background-image: none;
  transition: background-color 50000s ease-in-out 0s;
}
input {
  /* 下面这个色就是你设置的input框的背景色*/
  background-color: #595959;
}
 -->
<style >
 .inputSb  {
  -webkit-text-fill-color: white !important;
 }

 input:-webkit-autofill {
  /* x偏移量 | y偏移量 | 阴影模糊半径 | 阴影扩散半径 | 阴影颜色 */
  /* box-shadow: h-shadow v-shadow blur spread color inset; */

    box-shadow: 0 0 0px 0px blue  inset;
    -webkit-text-fill-color: black ;
    caret-color:  white ;
  }


</style>
